$(function(){
});

window.onload = function(){
	
	console.log("cue1");
	setTimeout(function(){
		$("#tmpKv").attr("src", $("#tmpKv").data("src"));
	}, 500);
	if(!_cmn){
		_cmn = CmnManager.create();
		_cmn.set();
		_cmn.start();
	}
}