/**
 * Manager
 * @class Page Manager
 * @returns
 */
var Manager = Klass.create();

Manager.extend(JqManager);

//definition start
Manager.extend({
/**
 * init
 */
init:function(){
	this.timer = null;
	this.perf_no = 1;
	this.app_no = 1;
	this.anim = false;
	this.$document = $(document);
	this.$window = $(window);
	this.bgVideoElms = document.querySelectorAll(".preloader");
	this.currentVideo = 0;
	this.videoNum = 5;
	this.slot = document.getElementById("slot");
	this.slot_directory = this.slot.dataset.directory;
	this.slotWords = [
		this.slot_directory + "/top/img/1_txt.png",
		this.slot_directory + "/top/img/2_txt.png",
		this.slot_directory + "/top/img/3_txt.png",
		this.slot_directory + "/top/img/4_txt.png",
		this.slot_directory + "/top/img/5_txt.png"
	];
	this.startWord = document.getElementById("startWord__inner");
	this.endWord = document.getElementById("endWord__inner");
},
/**
 * 設定
 */
set:function(){
	this.setParam();
	// this.setObject();
},
/**
 * 開始
 */
start:function(){
	console.log("start");
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this, this.setState, [],"");
	oCom.call(this, this.setObject, [],"");
	oCom.call(this, this.setEvent, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * 開始後
 */
after:function(){
	// if(_manager.spFlg === true) {
	// 	$("#top").height(window.innerHeight - $(".global_header").innerHeight());

	// } else {
	// 	$("#top").height(window.innerHeight - $(".global_header").innerHeight() - $("header").innerHeight());
	// }
	
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	//oCom.call(this, this.animAll, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//setting
///////////////////////////////////////////////////////////////////////////////////
/**
 * パラメータセット
 */
setParam:function(){
	var oSelf = this;

	_cmn.isTop = true;

	// IEの設定を生かす場合コメントアウトをとる
	// if(_cmn["ua"] === "ie" && ~~_cmn["ver"] === 8){
	// 	this.ie8 = true;
	// }
	// if(_cmn["ua"] !== "ie" || (_cmn["ua"] === "ie" && ~~_cmn["ver"] >= 10)){
	// 	this.modern = true;
	// }
},

/**
 * オブジェクト設定
 */
setObject:function(){
	this.over = $("#service_swiper").find(".over");
},
/**
 * 初期状態セット
 */
setState:function(){
	var oSelf = this;

	if(!_manager.ie8){
		if(_manager.modern){
			//modern
		}else{
			//ie9
		}
	}else{
		//ie8
	}
	$("body").removeClass("hide");

	this.setServiceSwiper();
  	this.setRecbnrSwiper();

	var videoCount = 0;
	this.currentVideo = 0;
	var iWindowW = window.innerWidth;
	
	for(var i in oSelf.bgVideoElms){
		elm = oSelf.bgVideoElms[i];
		try{
			var videoURL = "";
			if(iWindowW < 749){
				// sp
				videoURL = elm.dataset.sp;
			}else{
				videoURL = elm.dataset.pc;
			}

			elm.setAttribute('src', videoURL);

			elm.load();
			elm.addEventListener("loadeddata", function(e){
				console.log(e.currentTarget)
				// e.currentTarget.play();
				console.log("load");
				videoCount++;
				if(videoCount == oSelf.videoNum){
					console.log("comp");
					$(".topPage").addClass("view");
					oSelf.videoLoop(true);
				}
			})
			elm.addEventListener("ended", function(){
				this.style.opacity = "0";
			});
		}catch(e){
			console.log("------------------")
			console.log(e.massage);
			console.log(elm);
			console.log(i);
		}
	}
	
},
//KV部分 採用バナー用スライダー
setRecbnrSwiper:function(){
	var slide_length = $("#bnr_area_slide").find(".swiper-slide").length;
	if(slide_length < 2) {
		$("#top").find(".nav_arrow, .nav_num").addClass("hide");
		this.mySwiper_bnr = new Swiper('#bnr_area_slide', {
			speed: 400,
			spaceBetween: 0,
		loop: true,
		// allowTouchMove: false ,
			slidesPerView: "auto",
			// centeredSlides: false,
		});
	} else {
		this.mySwiper_bnr = new Swiper('#bnr_area_slide', {
			speed: 400,
			spaceBetween: 0,
		loop: true,
		// allowTouchMove: false ,
			slidesPerView: "auto",
			// centeredSlides: false,
		  autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
		});
	}
	
},
setTopicsSwiper:function(){
	this.mySwiper = new Swiper('#topics_swiper', {
		speed: 400,
		spaceBetween: 40,
		slidesPerView: "auto",
		centeredSlides: false,
		navigation: {
			nextEl: '#arrow_next',
			prevEl: '#arrow_prev',
		},
	});
},
setServiceSwiper:function(){
	this.mySpiper2_length = $("#service_swiper").find(".swiper-slide").length;
	this.mySwiper2 = new Swiper('#service_swiper', {
		speed: 0,
		spaceBetween: 10,
		slidesPerView: 1,
		centeredSlides: false,
		loop:true,
		effect: 'fade',
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		fadeEffect: {
			crossFade: true
		},
		navigation: {
			nextEl: '#arrow_next_service',
			prevEl: '#arrow_prev_service',
		},
		pagination: {
			el: '#pagination_service',
			type: 'bullets',
			clickable: true,
		},
	});
  this.mySwiper2.autoplay.stop(); //記事の切り替わり中に表示アニメーションが走ると不自然な挙動になるためinit時はオフに(浦野)
},
/**
 * setEvent
 */
setEvent:function(){
	var oSelf = this;
	this.resize(null,false);
	//cmn
	this.setWayPoint();
	this.setResize();
	this.setTopicsSwiper();


  //recluit_bnr
  // for(var i=0; i<$("#top .bnr_container .bnr_area ul li").length; i++) {
  //   var list_indx = 100 + i;
  //   $("#top .bnr_container .bnr_area ul li").eq(i).css("z-index",list_indx);
  // }

  // window.setTimeout(function(){
  // }, 400);

  //recluit_bnr
  $("#top .bnr_container .nav_arrow .a_left").on("click" , function(){
    oSelf.mySwiper_bnr.slidePrev();

	});

  $("#top .bnr_container .nav_arrow .a_right").on("click" , function(){
    oSelf.mySwiper_bnr.slideNext();

	});

  oSelf.mySwiper_bnr.on('slideChangeTransitionEnd', function () {
    var index = $('li.swiper-slide-active').data('swiper-slide-index');
    var index_num = index + 1;
    var index_num_str = String(index_num);
    $("#top .bnr_container .nav_num .active_num").text(index_num_str);
  });

	//modal_close
	$(".modal_close , .modal_bg").on("click" , function(e){
		$(".modal").removeClass("view");
		$(".modal").addClass("hide");
		$(".modal_li").removeClass("view");
		$(".modal_li").addClass("hide");
		$('html, body').css('overflow', 'visible');
	});

	$("#nav_people").on("click" , function(){
		_cmn.gotoTop("#people" , 0 , 1000);
		$("#menuBtn").trigger("click");
		return false;
	});


	
	// $(window).on("scroll" , function(){
	// 	$("#top").height(window.innerHeight - $(".global_header").innerHeight() - $("header").innerHeight());
	// });
	// this.mySwiper2.on('slideChange', function () {
	// 	console.log('change');
	// 	var anim_time = 500;
	// 	var oCom = new Command();
	// 	oCom.call(this,function(){
	// 		_manager.over.stop().css({x:"-105%"}).animate({x:0},anim_time,"easeOutQuint",function(){
	// 			$(this).stop().css({x:"0"}).animate({x:"105%"},anim_time,"easeOutQuint");
	// 		});
	// 	},[],"");
	// 	oCom.execute();
	// });
	
	this.mySwiper2.on('slideChange', function () {
		var previousIndex = _manager.mySwiper2.previousIndex;
		var activeIndex = _manager.mySwiper2.activeIndex;
		// ループ時
		if(_manager.mySwiper2.previousIndex > _manager.mySpiper2_length) {
			previousIndex = 1;
		}
		if(_manager.mySwiper2.previousIndex === 0){
			previousIndex = _manager.mySpiper2_length;
		}
		var anim_time = 500;
		var oCom = new Command();
		if(activeIndex > previousIndex) {
			oCom.call(this,function(){
				_manager.over.stop().css({x:"-105%"}).animate({x:0},anim_time,"easeOutQuint",function(){
						_manager.serviceSlideShow($("#service .swiper-slide-active"));
						_manager.serviceSlideHide($("#service .swiper-slide").not(".swiper-slide-active"));
					$(this).stop().css({x:"0"}).animate({x:"105%"},anim_time,"easeOutQuint", function(){
					});
				});
			},[],"");
		} else if(activeIndex < previousIndex) {
			oCom.call(this,function(){
				_manager.over.stop().css({x:"105%"}).animate({x:0},anim_time,"easeOutQuint",function(){
						_manager.serviceSlideShow($("#service .swiper-slide-active"));
						_manager.serviceSlideHide($("#service .swiper-slide").not(".swiper-slide-active"));
					$(this).stop().css({x:"0"}).animate({x:"-105%"},anim_time,"easeOutQuint", function(){
					});
				});
			},[],"");
		}
		oCom.execute();
	});
},
/**
 * スクロール位置ベースのイベント
 */
setWayPoint:function(){
	var oSelf = this;
	$(".txt").waypoint(function(direction){
		$(this).removeClass("hidden");
		console.log("misson_txt");
	},{offset: offset1});

	$(".article_list").waypoint(function(direction){
		$(this).removeClass("opacity_is_0");
		$(this).addClass("articleAnim");
		console.log("article_anim");
	},{offset: offset1});

   $("#service .swiper-container .swiper-wrapper .swiper-slide").waypoint(function(direction){
    $("#service .swiper-container .swiper-wrapper").removeClass("opacity_is_0");
		$(this).removeClass("opacity_is_0");
		$(this).addClass("articleAnim");
		_manager.serviceSlideShow($("#service .swiper-slide-active"));
    	oSelf.mySwiper2.autoplay.start();
		console.log("article_anim service");
	},{offset: offset1});

   $("#people ul li").waypoint(function(direction){
		$(this).removeClass("opacity_is_0");
		$(this).addClass("articleAnim");
      $("#people ul li").on('animationend', () => {
         $("#people ul li").css("pointer-events","visible");
       }); //写真が表示されるまでホバーできないように
		console.log("article_anim people");
	},{offset: offset1});

   $("#workplace ul").waypoint(function(direction){
		$("#workplace ul li").removeClass("opacity_is_0");
		$("#workplace ul li").addClass("articleAnim_workplace");
		console.log("article_anim workplace");
	},{offset: offset1});

	$("#people").waypoint(function(direction){
		if(direction == "down"){
			$("#nav_index a").removeClass("active");
			$("#nav_people a").addClass("active");
		}else{
			$("#nav_index a").addClass("active");
			$("#nav_people a").removeClass("active");
		}
	},{offset: 100});

	function offset1(){
		return window.innerHeight * 0.7;
	}
},
/**
 * リサイズイベントセット
 */
setResize:function(){
	$(window).bind("resize",function(e){_manager.resize(e,true);});
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
serviceSlideShow:function($target){
	$target.find(".img").find("img").css({opacity:1});
	$target.find(".cat").css({opacity:1});
	$target.find(".detail").css({opacity:1});
},
serviceSlideHide:function($target){
	$target.find(".img").find("img").css({opacity:0});
	$target.find(".cat").css({opacity:0});
	$target.find(".detail").css({opacity:0});
},
modalSelect:function(num){
	$("#modal_inner > li").addClass("hide");

	$("#modal_li" + num).removeClass("hide");
	$("#modal_li" + num).addClass("view");
	console.log("#modal_li" + num);

	this.modalCurrent = num;

	$(".modal_li > .detail").animate({scrollTop:0} , 500);
},
videoLoop: function(firstVisit){
	// 次の動画をランダムに選ぶ。前の動画と同じ場合は++する。
	var tmp = this.currentVideo;

	if(firstVisit){
		this.currentVideo = 0;
	}else{
		this.currentVideo = parseInt(Math.random() * this.videoNum);
		if(tmp == this.currentVideo){
			this.currentVideo++;
		}
	}

	if(this.currentVideo == this.videoNum){
		this.currentVideo = 0;
	}

	for(var i = 0; i < this.bgVideoElms.length; i++){
		// this.bgVideoElms[i].currentTime = 0;
		this.bgVideoElms[i].style.zIndex = "-1";
		// this.bgVideoElms[i].style.top = "-30vw";
	}
	
	this.bgVideoElms[this.currentVideo].style.zIndex = "0";
	this.bgVideoElms[this.currentVideo].style.opacity = "1";
	this.bgVideoElms[this.currentVideo].style.top = "0";
	this.bgVideoElms[this.currentVideo].style.filter = "blur(0)";
	this.bgVideoElms[this.currentVideo].play();

	var duration = this.bgVideoElms[this.currentVideo].duration - 1;
	this.slot.classList.remove("slotDisplay");

	var _oSelf = this;
	var animeTime = 1000;
	var delay = 500;

	setTimeout(() => {
		for(var i = 0 ; i < _oSelf.videoNum ; i++){
			if(i != _oSelf.currentVideo){
				_oSelf.bgVideoElms[i].style.top = "30vw";
				_oSelf.bgVideoElms[i].style.filter = "blur(1vw)";
			}
		}
	}, 1200);

	this.endWord.setAttribute('src', this.slotWords[this.currentVideo]);
	this.slot.classList.add("slotDisplay");

	setTimeout(() => {
		_oSelf.startWord.setAttribute('src', _oSelf.slotWords[_oSelf.currentVideo]);
	}, animeTime);
	setTimeout(() => {
		document.getElementById("endWord").classList.add("anime");
	}, animeTime + delay);

	setTimeout(() => {
		_oSelf.slot.classList.remove("slotDisplay");
		document.getElementById("endWord").classList.remove("anime");
	}, duration * 1000 - 50);

	

	setTimeout(function(){
		_oSelf.videoLoop(false);
	}, duration * 1000);
},
///////////////////////////////////////////////////////////////////////////////////
//util
///////////////////////////////////////////////////////////////////////////////////
/**
 * resize
 */
resize:function(e,b){
	var $window = $(window);
	var iWindowW = window.innerWidth;
	var iWindowH = window.innerHeight;
	if(iWindowW <= 749) {
		_manager.spFlg = true;
	} else{
		_manager.spFlg = false;
	}
	
}
});
//definition end